<script setup lang="ts">
import ThingModelArray from '@/components/custom/thing-model/thing-model-array.vue'
import ThingModelBool from '@/components/custom/thing-model/thing-model-bool.vue'
import ThingModelDate from '@/components/custom/thing-model/thing-model-date.vue'
import ThingModelDay from '@/components/custom/thing-model/thing-model-day.vue'
import ThingModelDecimal from '@/components/custom/thing-model/thing-model-decimal.vue'
import ThingModelEnum from '@/components/custom/thing-model/thing-model-enum.vue'
import ThingModelInt from '@/components/custom/thing-model/thing-model-int.vue'
import ThingModelStruct from '@/components/custom/thing-model/thing-model-struct.vue'
import ThingModelText from '@/components/custom/thing-model/thing-model-text.vue'

const props = defineProps(['specs'])
const emit = defineEmits(['confirm'])
const open = defineModel<boolean>('open')
const localSpecs = ref()
const formRef = ref()

const thingModels: any = {
  INT: ThingModelInt,
  DECIMAL: ThingModelDecimal,
  DATE: ThingModelDate,
  DAY: ThingModelDay,
  ENUM: ThingModelEnum,
  BOOL: ThingModelBool,
  TEXT: ThingModelText,
  STRUCT: ThingModelStruct,
  ARRAY: ThingModelArray,
}

function onChangeType(type: string) {
  let specs
  switch (type) {
    case 'INT':
    case 'DECIMAL':
      specs = {
        min: 0,
        max: 1,
        step: 1,
        unit: '',
        unitName: '',
      }
      break
    case 'DATE':
    case 'DAY':
    case 'TEXT':
      specs = {}
      break
    case 'ENUM':
    case 'STRUCT':
      specs = []
      break
    case 'BOOL':
      specs = { true: '', false: '' }
      break
    case 'ARRAY':
      specs = {
        item: {
          type: 'INT',
          specs: { min: 0, max: 1, step: 1, unit: '', unitName: '' },
        },
        size: 1,
      }
      break

    default:
      break
  }
  localSpecs.value.dataType.specs = specs
}

watch(open, (newOpen) => {
  if (newOpen) {
    if (props.specs) {
      localSpecs.value = props.specs
    }
    else {
      localSpecs.value = {
        dataType: {
          type: '',
        },
      }
    }
  }
})

function submit() {
  formRef.value.validate().then(async () => {
    open.value = false
    emit('confirm', localSpecs.value)
  })
}
</script>

<template>
  <a-modal v-model:open="open" width="50%" title="修改参数" :centered="true" @ok="submit">
    <a-form ref="formRef" :model="localSpecs" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="参数名称" :name="['name']" required>
        <a-input v-model:value="localSpecs.name" />
      </a-form-item>
      <a-form-item label="标识符" :name="['identifier']" required>
        <a-input v-model:value="localSpecs.identifier" />
      </a-form-item>
      <a-form-item label="数据类型" :name="['dataType', 'type']" required>
        <a-select
          v-model:value="localSpecs.dataType.type"
          :options="[
            { label: '整数型', value: 'INT' },
            { label: '浮点型', value: 'DECIMAL' },
            { label: '时间戳', value: 'DATE' },
            { label: '日期型', value: 'DAY' },
            { label: '枚举型', value: 'ENUM' },
            { label: '布尔型', value: 'BOOL' },
            { label: '文本型', value: 'TEXT' },
            { label: '结构体型', value: 'STRUCT' },
            { label: '数组型', value: 'ARRAY' },
          ]"
          @change="(val:any) => onChangeType(val)"
        />
      </a-form-item>

      <a-row v-if="localSpecs.dataType.type" style="margin-bottom: 24px;">
        <a-col :span="4" />
        <a-col :span="20">
          <a-card>
            <component :is="thingModels[localSpecs.dataType.type]" :specs="localSpecs.dataType.specs" :pre-name="['dataType', 'specs']" />
          </a-card>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>
