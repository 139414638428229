<script setup lang="ts">
import type { FormInstance } from 'ant-design-vue'
import { h } from 'vue'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons-vue'
import { isNumber } from '@v-c/utils'

import { addDeviceModelApi, editDeviceModelApi, getDeviceModelByIdApi } from '@/api/device-model'
import { getSubSystemsApi } from '@/api/sub-system'

const props = defineProps(['deviceModelId'])
const emit = defineEmits(['confirm'])
const open = defineModel<boolean>('open')
const formRef = ref<FormInstance>()
const localDeviceModel = ref({} as any)
const subsystems = ref<any[]>([])
const filesModalOpen = ref(false)
const message = useMessage()

watch(open, async (newOpen) => {
  if (newOpen) {
    await getSubsystem()
    if (props.deviceModelId) {
      await getDeviceModel()
    }
    else {
      localDeviceModel.value = {}
    }
  }
})

async function getSubsystem() {
  subsystems.value = await getSubSystemsApi()
}

async function getDeviceModel() {
  localDeviceModel.value = await getDeviceModelByIdApi(props.deviceModelId)
}

function onSubmit() {
  formRef.value?.validate().then(async () => {
    if (props.deviceModelId) {
      const isSuccess = await editDeviceModelApi(localDeviceModel.value)
      if (isSuccess) {
        message.success('修改成功')
        open.value = false
        emit('confirm')
      }
    }
    else {
      const res = await addDeviceModelApi(localDeviceModel.value)
      if (isNumber(res)) {
        message.success('添加成功')
        open.value = false
        emit('confirm')
      }
    }
  })
}

function onCancel() {
  formRef.value?.resetFields()
}

const iconAccessPath = ref()

function onChooseIcon(path: string) {
  iconAccessPath.value = path
  filesModalOpen.value = true
}

function afterChooseIcon({ accessPath, resourceId }: any) {
  if (!iconAccessPath.value)
    return

  setIconValue(iconAccessPath.value, accessPath, resourceId)
}

function setIconValue(path: string, accessPath?: string, resourceId?: string) {
  const iconKey = path.replace('AccessPath', '')
  localDeviceModel.value[iconKey] = resourceId ?? null
  localDeviceModel.value[path] = accessPath ?? null
}

function onDeleteIcon(path: string) {
  setIconValue(path)
}
</script>

<template>
  <a-modal v-model:open="open" :title="`${deviceModelId ? '修改' : '新增'}`" :centered="true" @ok="onSubmit" @cancel="onCancel">
    <a-form ref="formRef" :model="localDeviceModel" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="产品名称" name="deviceModelName" required>
        <a-input v-model:value="localDeviceModel.deviceModelName" placeholder="请输入模型名称" />
      </a-form-item>
      <a-form-item v-if="deviceModelId == null" label="产品编号" name="deviceModelCode" required>
        <a-input v-model:value="localDeviceModel.deviceModelCode" placeholder="推荐大写开头的驼峰式命名" />
      </a-form-item>
      <a-form-item label="子系统" name="subSysId">
        <a-select v-model:value="localDeviceModel.subSysId" :options="subsystems" :field-names="{ label: 'subSysName', value: 'id' }" />
      </a-form-item>
      <a-form-item label="默认图标" name="defaultIcon" required>
        <a-space>
          <a-image v-if="localDeviceModel.defaultIconAccessPath" :width="30" :height="30" :src="localDeviceModel.defaultIconAccessPath" :preview="false" />
          <a-button type="link" :icon="h(EditOutlined)" @click="onChooseIcon('defaultIconAccessPath')" />
          <a-button v-if="localDeviceModel.defaultIconAccessPath" type="link" danger :icon="h(DeleteOutlined)" @click="onDeleteIcon('defaultIconAccessPath')" />
        </a-space>
      </a-form-item>
      <a-form-item label="离线图标" name="offlineIcon">
        <a-space>
          <a-image v-if="localDeviceModel.offlineIconAccessPath" :width="30" :height="30" :src="localDeviceModel.offlineIconAccessPath" :preview="false" />
          <a-button type="link" :icon="h(EditOutlined)" @click="onChooseIcon('offlineIconAccessPath')" />
          <a-button v-if="localDeviceModel.offlineIconAccessPath" type="link" danger :icon="h(DeleteOutlined)" @click="onDeleteIcon('offlineIconAccessPath')" />
        </a-space>
      </a-form-item>
      <a-form-item label="在线图标" name="onlineIcon">
        <a-space>
          <a-image v-if="localDeviceModel.onlineIconAccessPath" :width="30" :height="30" :src="localDeviceModel.onlineIconAccessPath" :preview="false" />
          <a-button type="link" :icon="h(EditOutlined)" @click="onChooseIcon('onlineIconAccessPath')" />
          <a-button v-if="localDeviceModel.onlineIconAccessPath" type="link" danger :icon="h(DeleteOutlined)" @click="onDeleteIcon('onlineIconAccessPath')" />
        </a-space>
      </a-form-item>
      <a-form-item label="在线规则" name="onlineRuleExpression">
        <a-input v-model:value="localDeviceModel.onlineRuleExpression" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="备注" name="description">
        <a-input v-model:value="localDeviceModel.description" placeholder="请输入" />
      </a-form-item>
    </a-form>
    <FileChoose v-model:open="filesModalOpen" @on-choose-file="afterChooseIcon" />
  </a-modal>
</template>
