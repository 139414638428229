<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { cloneDeep } from 'lodash-es'
import { isNumber } from '@v-c/utils'
import { CheckCircleFilled } from '@ant-design/icons-vue'
import { addSubSysApi, delSubSysByIdApi, querySubSysListApi, updateSubSysApi } from '@/api/sub-system'
import { cache } from '@/utils/tools'

const emits = defineEmits(['confirm'])
const message = useMessage()

const open = defineModel<boolean>('open')
const subSysList = ref<any[]>([])

function clearArray(arr: any[]) {
  arr.splice(0)
}

watch(open, async (newOpen) => {
  if (newOpen === true) {
    await onRefresh()
  }
})

async function onChange(subSys: any) {
  const nextSubsys = cloneDeep(subSys)

  // 新增
  if (nextSubsys.id == null) {
    const subsystemId = await addSubSysApi(nextSubsys)
    if (isNumber(subsystemId)) {
      await onRefresh()
      emits('confirm')
      message.success('添加成功')
    }
  }
  else {
    // 修改
    const isSuccess = await updateSubSysApi(nextSubsys)
    if (isSuccess === true) {
      await onRefresh()
      emits('confirm')
      subSys.isEdit = false
      message.success('修改成功')
    }
  }
}

function onCancelEdit() {
  onCacheRefresh()
}

async function onDel(id: number) {
  const isSuccess = await delSubSysByIdApi(id)
  if (isSuccess === true) {
    await onRefresh()
    emits('confirm')
    message.success('删除成功')
  }
}

function onEdit(id: number) {
  onCacheRefresh()
  subSysList.value.forEach((item) => {
    item.isEdit = item.id === id
  })
}

function onAdd() {
  onCacheRefresh()
  subSysList.value.forEach(item => (item.isEdit = false))

  subSysList.value.unshift({
    subSysName: '',
    isEdit: true,
  })
}

onMounted(async () => {
  await onRefresh()
})

async function onRefresh() {
  const res = await querySubSysListApi()

  clearArray(subSysList.value)
  subSysList.value.push(...res)
  subSysList.value.forEach(item => (item.isEdit = false))
  cache(cloneDeep(subSysList.value), true)
}

function onCacheRefresh() {
  subSysList.value = cloneDeep(cache())
}
</script>

<template>
  <a-modal v-model:open="open" width="50%" centered title="子系统管理" :footer="null">
    <div class="operation">
      <a-button type="link" @click="onAdd">
        新增子系统
      </a-button>
    </div>
    <div class="sub-sys-dialog-content" h-400px>
      <scroll>
        <div v-for="item in subSysList" :key="item.id" class="sub-sys-row">
          <div class="sub-sys-name" flex items-center>
            <span v-if="!item.isEdit">{{ item.subSysName }} </span>
            <a-space v-if="item.isEdit">
              <a-input v-model:value="item.subSysName" placeholder="" @keyup.enter="() => onChange(item)" />
              <CheckCircleFilled cursor-pointer style="font-size: 20px;color: #52c41a;" @click="onChange(item)" />
            </a-space>
          </div>
          <div class="sub-sys-operation">
            <a-button v-if="!item.isEdit" type="link" @click="onEdit(item.id)">
              编辑
            </a-button>
            <a-button v-if="item.isEdit" type="link" @click="onCancelEdit">
              取消编辑
            </a-button>
            <a-popconfirm
              v-if="item.id != null"
              title="确定要删除该子系统?"
              @confirm="onDel(item.id)"
            >
              <a-button type="link" danger>
                删除
              </a-button>
            </a-popconfirm>
          </div>
        </div>
      </scroll>
    </div>
  </a-modal>
</template>

<style lang="scss" scoped>
.operation {
	padding: 5px 10px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.sub-sys-dialog-content {
	height: 400px;

	.sub-sys-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px 10px;

		.sub-sys-name {
			.edit-btn {
				margin-left: 10px;
			}
		}
	}
}
</style>
