<script setup lang="ts">
import type { FormInstance } from 'ant-design-vue'
import { cloneDeep } from 'lodash-es'
import { h } from 'vue'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons-vue'
import { useAbilityModelState } from './useState'
import { addDeviceModelRulesExpressionApi, updateDeviceModelRulesExpressionApi } from '@/api/device-model'

const props = defineProps(['expression'])
const emit = defineEmits(['confirm'])
const { deviceModelCode } = useAbilityModelState()
const message = useMessage()
const formRef = ref<FormInstance>()
const open = defineModel<boolean>('open')
const localExpression = ref({} as any)
const filesModalOpen = ref(false)
const operation = ref('add')

function onSubmit() {
  formRef.value?.validate().then(async () => {
    if (operation.value === 'add') {
      localExpression.value.deviceModelCode = deviceModelCode.value
      await addDeviceModelRulesExpressionApi(localExpression.value)
      message.success('新增成功')
      open.value = false
      onCancel()
      emit('confirm')
    }
    else {
      await updateDeviceModelRulesExpressionApi(localExpression.value)
      message.success('修改成功')
      open.value = false
      onCancel()
      emit('confirm')
    }
  })
}

function onCancel() {
  formRef.value?.resetFields()
}

watchEffect(() => {
  if (props.expression) {
    localExpression.value = cloneDeep(props.expression)
    operation.value = 'update'
  }
  else {
    localExpression.value = {}
    operation.value = 'add'
  }
})

const iconAccessPath = ref()

function afterChooseIcon({ accessPath, resourceId }: any) {
  if (!iconAccessPath.value)
    return

  setIconValue(iconAccessPath.value, accessPath, resourceId)
}

function setIconValue(path: string, accessPath?: string, resourceId?: string) {
  const iconKey = path.replace('Path', '')
  localExpression.value[iconKey] = resourceId ?? null
  localExpression.value[path] = accessPath ?? null
}

function onDeleteIcon(path: string) {
  setIconValue(path)
}

function onChooseIcon(path: string) {
  iconAccessPath.value = path
  filesModalOpen.value = true
}
</script>

<template>
  <a-modal v-model:open="open" width="50%" :title="`${operation === 'update' ? '修改' : '新增'}`" :centered="true" @ok="onSubmit" @cancel="onCancel">
    <a-form ref="formRef" :model="localExpression" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="表达式名称" name="expressionName" required>
        <a-input v-model:value="localExpression.expressionName" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="表达式" name="expression" required>
        <a-input v-model:value="localExpression.expression" placeholder="请输入" />
        <div mt-10px>
          <a-alert
            message="注意特殊变量：online"
            type="warning"
            show-icon
          >
            <template #description>
              <a-badge color="blue" text="如果能力模型中没有编号为online的属性、静态信息或事件，则online代表系统内置变量：在线状态，其值为true或false。" />
              <a-badge color="blue" text="如果能力模型中存在编号为online的属性、静态信息或事件，则online代表此能力，此时你无法获取系统内置变量：在线状态" />
            </template>
          </a-alert>
        </div>
      </a-form-item>

      <a-form-item label="绑定图标" name="bindIconPath">
        <a-space>
          <a-image v-if="localExpression.bindIconPath" :width="30" :height="30" :src="localExpression.bindIconPath" :preview="false" />
          <a-button type="link" :icon="h(EditOutlined)" @click="onChooseIcon('bindIconPath')" />
          <a-button v-if="localExpression.bindIconPath" type="link" danger :icon="h(DeleteOutlined)" @click="onDeleteIcon('bindIconPath')" />
        </a-space>
      </a-form-item>
      <a-form-item v-if="localExpression.bindIconPath" label="图标优先级" name="bindIconPriority">
        <a-input-number v-model:value="localExpression.bindIconPriority" />
      </a-form-item>
    </a-form>
    <FileChoose v-model:open="filesModalOpen" @on-choose-file="afterChooseIcon" />
  </a-modal>
</template>
