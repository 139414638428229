<script setup lang="ts">
interface Specs {
  false: string
  true: string
}

interface Props {
  specs: Specs
  preName: string[]
}

// 使用 withDefaults 创建 props
const props = withDefaults(defineProps<Props>(), {
  specs: () => ({ true: '', false: '' }),
})

// 解构 specs prop 为响应式的本地属性
const { specs } = toRefs(props)
</script>

<template>
  <a-space>
    <a-form-item :name="[...preName, 'true']" required help="">
      <a-input v-model:value="specs.true">
        <template #addonBefore>
          true
        </template>
      </a-input>
    </a-form-item>
    <a-form-item :name="[...preName, 'false']" required help="">
      <a-input v-model:value="specs.false">
        <template #addonBefore>
          false
        </template>
      </a-input>
    </a-form-item>
  </a-space>
</template>

<style lang="scss" scoped>
.ant-form-item{
  margin-bottom: 0px;
}
</style>
