<script setup lang="ts">
import { Button, Popconfirm, Space } from 'ant-design-vue'
import RuleStatusUpdate from './rule-status-update.vue'
import { useAbilityModelState } from './useState'
import { deleteDeviceModelStatusApi, getDeviceModelStateApi } from '@/api/device-model'

const { deviceModelCode } = useAbilityModelState()
const updateModalVisible = ref(false)
const choosedStatus = ref()

const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '状态组名称', dataIndex: 'stateGroupName' },
    { title: '状态名称', dataIndex: 'stateName' },
    { title: '优先级', dataIndex: 'priority' },
    { title: '规则表达式', dataIndex: 'deviceModelExpressionId' },
    { title: '操作', dataIndex: '', customRender: ({ record }) => {
      return h(Space, {}, () => [
        h(Button, { type: 'link', size: 'small', onClick: () => {
          choosedStatus.value = record
          updateModalVisible.value = true
        } }, () => '编辑'),
        h(
          Popconfirm,
          { title: '确定删除？', onConfirm: async () => {
            await deleteDeviceModelStatusApi(record.id)
            await refresh()
          } },
          () => h(
            Button,
            { danger: true, type: 'link', size: 'small' },
            () => '删除',
          ),
        ),
      ])
    } },
  ],
  config: {
    total: 0, // 列表总数
    loading: false, // loading 加载
    isBorder: false, // 是否显示表格边框
    isSerialNo: true, // 是否显示表格序号
    isSelection: true, // 是否显示表格多选
    isOperate: true, // 是否显示表格操作栏
  },
  pagination: false,
})

onMounted(refresh)

function onAddExpression() {
  choosedStatus.value = undefined
  updateModalVisible.value = true
}

async function refresh() {
  state.config.loading = true
  state.data = await getDeviceModelStateApi(deviceModelCode.value)
  state.config.loading = false
  setTimeout(() => {
    state.config.loading = false
  }, 3000)
}

const groupOptions = computed(() => {
  const set = new Set()
  state.data.forEach(item => (set.add(item.stateGroupName)))
  return Array.from(set)
})
</script>

<template>
  <flow-hidden-container>
    <table-layout>
      <template #headerLeft>
        <a-space size="middle">
          <a-button type="primary" @click="onAddExpression">
            新增规则定义
          </a-button>
        </a-space>
      </template>
      <template #content>
        <base-table v-bind="state" />
      </template>
    </table-layout>
  </flow-hidden-container>
  <RuleStatusUpdate v-model:open="updateModalVisible" :status="choosedStatus" :group-options="groupOptions" @confirm="refresh" />
</template>
