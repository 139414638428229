<script setup lang="ts">
import ThingModelDecimal from './thing-model-decimal.vue'
import ThingModelInt from './thing-model-int.vue'
import ThingModelStruct from './thing-model-struct.vue'
import ThingModelText from './thing-model-text.vue'

interface Specs {
  item: any
  size: number
}

interface Props {
  specs: Specs
  preName: string[]
}

// 使用 withDefaults 创建 props
const props = withDefaults(defineProps<Props>(), {
  specs: () => ({
    item: {},
    size: 1,
  }),
})

// 解构 specs prop 为响应式的本地属性
const { specs } = toRefs(props)

const tabs: any = {
  INT: ThingModelInt,
  DECIMAL: ThingModelDecimal,
  TEXT: ThingModelText,
  STRUCT: ThingModelStruct,
}

function onChange(type: any) {
  let localSpecs
  switch (type.target.value) {
    case 'INT':
    case 'DECIMAL':

      localSpecs = {
        min: 0,
        max: 1,
        step: 1,
        unit: '',
        unitName: '',
      }
      break
    case 'TEXT':
      localSpecs = {}
      break
    case 'STRUCT':
      localSpecs = []
      break
    default:
      break
  }

  specs.value.item.specs = localSpecs
}
</script>

<template>
  <a-form-item :name="[...preName, 'item', 'type']" required label="元素类型">
    <a-radio-group v-model:value="specs.item.type" @change="onChange">
      <a-radio value="INT">
        整数型
      </a-radio>
      <a-radio value="DECIMAL">
        浮点型
      </a-radio>
      <a-radio value="TEXT">
        文本型
      </a-radio>
      <a-radio value="STRUCT">
        结构体
      </a-radio>
    </a-radio-group>
  </a-form-item>
  <a-form-item :name="[...preName, 'size']" required label="元素个数">
    <a-input-number v-model:value="specs.size" />
  </a-form-item>
  <a-row>
    <a-col :span="4" />
    <a-col :span="20">
      <a-card>
        <component :is="tabs[specs.item.type]" :specs="specs.item.specs" :pre-name="[...preName, 'item', 'specs']" />
      </a-card>
    </a-col>
  </a-row>
</template>
