<script setup lang="ts">
import type { ColumnsType } from 'ant-design-vue/es/table'

interface Specs {
  keys: string
  value: string
}

interface Props {
  specs: Specs[]
  preName: string[]
}

// 使用 withDefaults 创建 props
const props = withDefaults(defineProps<Props>(), {
  specs: () => [],
})

// 解构 specs prop 为响应式的本地属性
const { specs } = toRefs(props)

const columns = ref<ColumnsType>([{ title: '参数值', dataIndex: 'keys' }, { title: '参数描述', dataIndex: 'value' }, { title: '操作', dataIndex: '' }])

function onDelete(index: number) {
  specs.value.splice(index, 1)
}

function onAdd() {
  specs.value.push({ keys: '', value: '' })
}
</script>

<template>
  <a-table :data-source="specs" :columns="columns" :pagination="false" :scroll="{ y: 300 }">
    <template #bodyCell="{ column, record, index }">
      <template v-if="column.dataIndex === 'keys'">
        <a-form-item :name="[...preName, index, 'keys']" required help="">
          <a-input v-model:value="record.keys" />
        </a-form-item>
      </template>
      <template v-else-if="column.dataIndex === 'value'">
        <a-form-item :name="[...preName, index, 'value']" required help="">
          <a-input v-model:value="record.value" />
        </a-form-item>
      </template>
      <template v-else>
        <a-button type="link" @click="onDelete(index)">
          删除
        </a-button>
      </template>
    </template>
  </a-table>
  <a-button type="dashed" style="width: 100%;" @click="onAdd">
    添加
  </a-button>
</template>

<style lang="scss" scoped>
.ant-form-item{
  margin-bottom: 0px;
}
</style>
