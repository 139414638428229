<script setup lang="ts">
import { PlusOutlined } from '@ant-design/icons-vue'
import { Button, Image, Popconfirm, Space } from 'ant-design-vue'
import Update from './update.vue'
import SubsysManage from './subsys-manage.vue'
import { deleteDeviceModelByIdApi, getDeviceAbilityModelListApi, getDeviceModelsApi } from '@/api/device-model'
import { cache } from '@/utils/tools'

const pageType = defineModel('pageType')
const deviceModel = defineModel('deviceModel')
const updateModalOpen = ref(false)
const choosedDeviceModel = ref()
const subsysVisible = ref(false)
const searchWords = ref()

const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '产品名称', dataIndex: ['deviceModelName'] },
    { title: '产品编号', dataIndex: ['deviceModelCode'] },
    { title: '所属子系统', dataIndex: ['subSysName'] },
    {
      title: '默认图标',
      dataIndex: ['defaultIconAccessPath'],
      customRender({ record }) {
        return record.defaultIconAccessPath ? h(Image, { preview: false, src: record.defaultIconAccessPath, width: 40, height: 40 }) : ''
      },
    },
    {
      title: '离线图标',
      dataIndex: ['offlineIconAccessPath'],
      customRender({ record }) {
        return record.offlineIconAccessPath ? h(Image, { preview: false, src: record.offlineIconAccessPath, width: 40, height: 40 }) : ''
      },
    },
    {
      title: '在线图标',
      dataIndex: ['onlineIconAccessPath'],
      customRender({ record }) {
        return record.onlineIconAccessPath ? h(Image, { preview: false, src: record.onlineIconAccessPath, width: 40, height: 40 }) : ''
      },
    },
    { title: '在线规则', dataIndex: ['onlineRuleExpression'] },
    { title: '备注', dataIndex: ['description'] },
    {
      title: '操作',
      width: 210,
      customRender: ({ record }) => {
        return h(
          Space,
          { direction: 'horizontal' },
          () => [
            h(
              Button,
              { type: 'link', size: 'small', onClick: () => { onEdit(record) } },
              () => '编辑',
            ),
            h(
              Button,
              {
                type: 'link',
                size: 'small',
                onClick: () => {
                  goDetail(record)
                },
              },
              () => '详情',
            ),
            h(
              Popconfirm,
              {
                title: '确定删除？',
                onConfirm: () => {
                  onDelete(record)
                },
              },
              () => h(
                Button,
                { danger: true, type: 'link', size: 'small' },
                () => '删除',
              ),
            ),
          ],
        )
      },
    },
  ],
  config: {
    total: 0,
    loading: false,
    isBorder: false,
    isSerialNo: true,
    isSelection: true,
    isOperate: true,
  },
  pagination: false,
})

onMounted(refresh)

async function refresh() {
  state.config.loading = true
  state.data = await getDeviceModelsApi()
  state.config.loading = false
  setTimeout(() => {
    state.config.loading = false
  }, 3000)
}

function onAddInstance() {
  updateModalOpen.value = true
  choosedDeviceModel.value = undefined
}

function onEdit(deviceModel: any) {
  updateModalOpen.value = true
  choosedDeviceModel.value = deviceModel
}

function goDetail(record: string) {
  deviceModel.value = record
  pageType.value = 'detail'
}

async function onDelete(record: any) {
  const isSuccess = await deleteDeviceModelByIdApi(record.id)
  if (!isSuccess)
    return
  await refresh()
}

onMounted(getThingModel)

const formData = ref()
async function getThingModel() {
  const res = await getDeviceAbilityModelListApi('SmartLightOO')
  const aim = res.find((item: any) => {
    return item.abilityType === 'PROFILE' && item.detail.dataType.type === 'ARRAY' && item.detail.dataType.specs.item.type === 'STRUCT'
  })

  formData.value = aim
}

function onAddSubsys() {
  subsysVisible.value = true
}

onMounted(() => { cache([], true) })

async function onSearch() {
  await refresh()

  state.data = state.data.filter(item => item.deviceModelName.includes(searchWords.value))
}
</script>

<template>
  <content-header title="产品管理" />
  <flex-content>
    <table-layout>
      <template #headerLeft>
        <a-space size="middle">
          <a-button type="primary" @click="onAddInstance">
            <template #icon>
              <PlusOutlined />
            </template>
            新增产品
          </a-button>
          <a-button type="primary" @click="onAddSubsys">
            子系统管理
          </a-button>
        </a-space>
      </template>
      <template #headerRight>
        <a-space size="middle">
          <a-input v-model:value="searchWords" placeholder="请输入产品名称" allow-clear />
          <a-button type="primary" @click="onSearch">
            搜索
          </a-button>
        </a-space>
      </template>

      <template #content>
        <base-table v-bind="state" />
      </template>
    </table-layout>
  </flex-content>
  <SubsysManage v-model:open="subsysVisible" />
  <Update v-model:open="updateModalOpen" :device-model-id="choosedDeviceModel?.id" @confirm="refresh" />
</template>

<style lang="scss" scoped>
.table-layout {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
}
</style>
