<script setup lang="ts">
import { cloneDeep, map, reduce } from 'lodash-es'
import type { ColumnsType } from 'ant-design-vue/es/table'
import { useAbilityModelState } from './useState'
import ThingModelArray from '@/components/custom/thing-model/thing-model-array.vue'
import ThingModelBool from '@/components/custom/thing-model/thing-model-bool.vue'
import ThingModelDate from '@/components/custom/thing-model/thing-model-date.vue'
import ThingModelDay from '@/components/custom/thing-model/thing-model-day.vue'
import ThingModelDecimal from '@/components/custom/thing-model/thing-model-decimal.vue'
import ThingModelEnum from '@/components/custom/thing-model/thing-model-enum.vue'
import ThingModelInt from '@/components/custom/thing-model/thing-model-int.vue'
import ThingModelStruct from '@/components/custom/thing-model/thing-model-struct.vue'
import ThingModelText from '@/components/custom/thing-model/thing-model-text.vue'
import { addDeviceAbilityModelApi, updateDeviceAbilityModelApi } from '@/api/device-model'

const props = defineProps(['deviceModelAbility'])
const emit = defineEmits(['confirm'])
const { deviceModelCode } = useAbilityModelState()
const open = defineModel<boolean>('open')
const thingModelEditOpen = ref(false)

const localAbility = ref()
const operation = ref('add')
const formRef = ref()

watch(open, (newOpen) => {
  if (newOpen === true) {
    if (props.deviceModelAbility) {
      operation.value = 'edit'
      localAbility.value = cloneDeep(props.deviceModelAbility)
      if (localAbility.value.detail.dataType?.type === 'ENUM') {
        transformEnumSpecs(localAbility.value)
      }
    }
    else {
      operation.value = 'add'

      // localAbility.value = {
      //   detail: {
      //     dataType: {
      //       type: undefined,
      //       specs: undefined,
      //     },
      //   },
      // }
      onChangeEventType('PROPERTY')
    }
  }
})

function transformEnumSpecs(ability: any) {
  ability.detail.dataType.specs = map(ability.detail.dataType.specs, (value, key) => {
    return { keys: key, value }
  })
}

const thingModels: any = {
  INT: ThingModelInt,
  DECIMAL: ThingModelDecimal,
  DATE: ThingModelDate,
  DAY: ThingModelDay,
  ENUM: ThingModelEnum,
  BOOL: ThingModelBool,
  TEXT: ThingModelText,
  STRUCT: ThingModelStruct,
  ARRAY: ThingModelArray,
}

function onChangeType(type: string) {
  let specs
  switch (type) {
    case 'INT':
    case 'DECIMAL':
      specs = {
        min: 0,
        max: 1,
        step: 1,
        unit: '',
        unitName: '',
      }
      break
    case 'DATE':
    case 'DAY':
    case 'TEXT':
      specs = {}
      break
    case 'ENUM':
    case 'STRUCT':
      specs = []
      break
    case 'BOOL':
      specs = { true: '', false: '' }
      break
    case 'ARRAY':
      specs = {
        item: {
          type: 'INT',
          specs: { min: 0, max: 1, step: 1, unit: '', unitName: '' },
        },
        size: 1,
      }
      break

    default:
      break
  }
  localAbility.value.detail.dataType.specs = specs
}

function submit() {
  formRef.value.validate().then(async () => {
    const param = cloneDeep(localAbility.value)
    if (param.detail.dataType?.type === 'ENUM') {
      param.detail.dataType.specs = reduce<any, any>(param.detail.dataType.specs, (acc, { keys, value }) => {
        acc[keys] = value
        return acc
      }, {})
    }
    param.detail = JSON.stringify(param.detail)
    if (operation.value === 'add') {
      param.deviceModelCode = deviceModelCode.value
      await addDeviceAbilityModelApi(param)
      open.value = false
      onCancel()
      emit('confirm')
    }
    else {
      await updateDeviceAbilityModelApi(param)
      open.value = false
      onCancel()
      emit('confirm')
    }
  })
}

function onCancel() {
  // formRef.value.resetFields()
}

function onChangeEventType(type: any) {
  switch (type) {
    case 'PROFILE':
    case 'PROPERTY':
      localAbility.value = {
        detail: {
          dataType: {
            type: undefined,
            specs: undefined,
          },
        },
      }

      localAbility.value.detail.accessMode = 'READ_ONLY'
      break
    case 'EVENT':
      localAbility.value = {
        detail: {
          outputData: [],
        },
      }
      break
    case 'SERVICE':
      localAbility.value = {
        detail: {
          outputData: [],
          inputData: [],
        },
      }
      break

    default:
      break
  }

  localAbility.value.abilityType = type
}

const columns = ref<ColumnsType>([{ title: '序号', width: 120, customRender({ index }) { return index + 1 } }, { title: '参数名称', dataIndex: ['name'] }, { title: '操作', dataIndex: 'operation' }])

const inOrOutput = ref<'input' | 'output'>('input')
const thingModelEditOperation = ref('add')
const editIndex = ref(0)
const choosedSpecs = ref()
function onAdd(dataType: 'input' | 'output') {
  inOrOutput.value = dataType
  thingModelEditOperation.value = 'add'
  thingModelEditOpen.value = true
}

function onEdit(record: any, index: number, dataType: 'input' | 'output') {
  inOrOutput.value = dataType
  thingModelEditOperation.value = 'edit'
  editIndex.value = index
  choosedSpecs.value = record
  thingModelEditOpen.value = true
}

function afterEdit(record: any) {
  const isService = localAbility.value.abilityType === 'SERVICE'
  const isInput = inOrOutput.value === 'input'

  // 判定操作类型
  const targetData = isService
    ? (isInput ? localAbility.value.detail.inputData : localAbility.value.detail.outputData)
    : localAbility.value.detail.outputData

  // 添加或编辑记录
  if (thingModelEditOperation.value === 'add') {
    targetData.push(record) // 添加新记录
  }
  else {
    targetData[editIndex.value] = record // 编辑现有记录
  }
}

function onEditEvent(record: any, index: number) {
  choosedSpecs.value = record
  editIndex.value = index
  thingModelEditOperation.value = 'edit'
  thingModelEditOpen.value = true
}

function onAddEvent() {
  thingModelEditOpen.value = true
  thingModelEditOperation.value = 'add'
}
</script>

<template>
  <a-modal v-model:open="open" width="60%" :title="`${operation === 'add' ? '新增' : '修改'}`" :centered="true" @ok="submit" @cancel="onCancel">
    <div h-600px>
      <scroll>
        <a-form v-if="localAbility" ref="formRef" :model="localAbility" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
          <a-form-item label="功能类型" name="abilityType">
            <a-select v-model:value="localAbility.abilityType" :disabled="operation === 'edit'" :options="[{ label: '属性', value: 'PROPERTY' }, { label: '服务', value: 'SERVICE' }, { label: '事件', value: 'EVENT' }, { label: '静态信息', value: 'PROFILE' }]" @change="onChangeEventType" />
          </a-form-item>
          <a-form-item label="能力名称" name="abilityName" required>
            <a-input v-model:value="localAbility.abilityName" />
          </a-form-item>
          <a-form-item label="能力编号" name="abilityCode" required>
            <a-input v-model:value="localAbility.abilityCode" :disabled="operation === 'edit'" />
          </a-form-item>
          <template v-if="localAbility.abilityType === 'PROPERTY' || localAbility.abilityType === 'PROFILE'">
            <a-form-item label="数据类型" :name="['detail', 'dataType', 'type']" required>
              <a-select
                v-model:value="localAbility.detail.dataType.type"
                :options="[
                  { label: '整数型', value: 'INT' },
                  { label: '浮点型', value: 'DECIMAL' },
                  { label: '时间戳', value: 'DATE' },
                  { label: '日期型', value: 'DAY' },
                  { label: '枚举型', value: 'ENUM' },
                  { label: '布尔型', value: 'BOOL' },
                  { label: '文本型', value: 'TEXT' },
                  { label: '结构体型', value: 'STRUCT' },
                  { label: '数组型', value: 'ARRAY' },
                ]"
                @change="(val:any) => onChangeType(val)"
              />
            </a-form-item>
            <a-row v-if="localAbility.detail.dataType.type" style="margin-bottom: 24px;">
              <a-col :span="4" />
              <a-col :span="20">
                <a-card>
                  <component :is="thingModels[localAbility.detail.dataType.type]" :specs="localAbility.detail.dataType.specs" :pre-name="['detail', 'dataType', 'specs']" />
                </a-card>
              </a-col>
            </a-row>
            <a-form-item v-if="localAbility.abilityType === 'PROPERTY'" label="读写类型" :name="['detail', 'accessMode']" required>
              <a-radio-group v-model:value="localAbility.detail.accessMode">
                <a-radio value="READ_ONLY">
                  只读
                </a-radio>
                <a-radio value="READ_WRITE">
                  读写
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item
              v-if="localAbility.abilityType === 'PROPERTY' && (localAbility.detail.dataType.type === 'INT' || localAbility.detail.dataType.type === 'DECIMAL')"
              label="是否累计值" :name="['propertyCumulative']" required
            >
              <a-radio-group v-model:value="localAbility.propertyCumulative">
                <a-radio :value="true">
                  是
                </a-radio>
                <a-radio :value="false">
                  否
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </template>

          <template v-if="localAbility.abilityType === 'SERVICE'">
            <a-form-item label="输入参数">
              <a-table :data-source="localAbility.detail.inputData" :columns="columns" :pagination="false" :scroll="{ y: 200 }">
                <template #bodyCell="{ column, record, index }">
                  <template v-if="column.dataIndex === 'operation'">
                    <a-space>
                      <a-button type="link" @click="onEdit(record, index, 'input')">
                        编辑
                      </a-button>
                      <a-button type="link" danger @click="localAbility.detail.inputData.splice(index, 1)">
                        删除
                      </a-button>
                    </a-space>
                  </template>
                </template>
              </a-table>
              <a-button type="dashed" style="width: 100%;" @click="onAdd('input')">
                添加
              </a-button>
            </a-form-item>
            <a-form-item label="输出参数">
              <a-table :data-source="localAbility.detail.outputData" :columns="columns" :pagination="false" :scroll="{ y: 200 }">
                <template #bodyCell="{ column, record, index }">
                  <template v-if="column.dataIndex === 'operation'">
                    <a-space>
                      <a-button type="link" @click="onEdit(record, index, 'output')">
                        编辑
                      </a-button>
                      <a-button type="link" danger @click="localAbility.detail.outputData.splice(index, 1)">
                        删除
                      </a-button>
                    </a-space>
                  </template>
                </template>
              </a-table>
              <a-button type="dashed" style="width: 100%;" @click="onAdd('output')">
                添加
              </a-button>
            </a-form-item>
            <a-form-item label="设备响应" :name="['detail', 'callType']" required>
              <a-radio-group v-model:value="localAbility.detail.callType">
                <a-radio value="ASYNC">
                  等待
                </a-radio>
                <a-radio value="SYNC">
                  不等待
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </template>

          <template v-if="localAbility.abilityType === 'EVENT'">
            <a-form-item label="事件类型" :name="['detail', 'type']" required>
              <a-radio-group v-model:value="localAbility.detail.type">
                <a-radio value="INFO">
                  信息
                </a-radio>
                <a-radio value="ALERT">
                  告警
                </a-radio>
                <a-radio value="ERROR">
                  故障
                </a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="输出参数">
              <a-table :data-source="localAbility.detail.outputData" :columns="columns" :pagination="false" :scroll="{ y: 200 }">
                <template #bodyCell="{ column, record, index }">
                  <template v-if="column.dataIndex === 'operation'">
                    <a-space>
                      <a-button type="link" @click="onEditEvent(record, index)">
                        编辑
                      </a-button>
                      <a-button type="link" danger @click="localAbility.detail.outputData.splice(index, 1)">
                        删除
                      </a-button>
                    </a-space>
                  </template>
                </template>
              </a-table>
              <a-button type="dashed" style="width: 100%;" @click="onAddEvent">
                添加
              </a-button>
            </a-form-item>
          </template>

          <a-form-item label="描述" name="description">
            <a-input v-model:value="localAbility.description" />
          </a-form-item>
        </a-form>
      </scroll>
    </div>

    <ThingModelEdit v-model:open="thingModelEditOpen" :specs="choosedSpecs" @confirm="afterEdit" />
  </a-modal>
</template>
