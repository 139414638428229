<script setup lang="ts">
import list from './components/list.vue'
import detail from './components/detail.vue'

const pageType = ref<'list' | 'detail'>('list')
const deviceModel = ref()
</script>

<template>
  <page-container>
    <list v-if="pageType === 'list'" v-model:page-type="pageType" v-model:device-model="deviceModel" />
    <detail v-else v-model:page-type="pageType" :device-model="deviceModel" />
  </page-container>
</template>
