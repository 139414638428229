<script setup lang="ts">
import 'vue-json-pretty/lib/styles.css'
import AbilityModel from './ability-model.vue'
import RuleExpression from './rule-expression.vue'
import RuleStatus from './rule-status.vue'
import { useProviderAbilityModelStore } from './useState'

const props = defineProps(['deviceModel'])
const pageType = defineModel('pageType')
const activeKey = ref('1')

useProviderAbilityModelStore(props.deviceModel)

function back() {
  pageType.value = 'list'
}
</script>

<template>
  <content-header :title="deviceModel.deviceModelName" :need-back="true" @on-back="back" />
  <div flex-1 overflow-hidden>
    <a-tabs v-model:active-key="activeKey">
      <a-tab-pane key="1" tab="设备能力模型">
        <block-content>
          <AbilityModel />
        </block-content>
      </a-tab-pane>
      <a-tab-pane key="2" tab="aviator表达式">
        <block-content>
          <RuleExpression />
        </block-content>
      </a-tab-pane>
      <a-tab-pane key="3" tab="状态组">
        <block-content><RuleStatus /></block-content>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/ant-reset/tabs.scss';
</style>
