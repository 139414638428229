<script setup lang="ts">
import { Button, Popconfirm, Space, Tag } from 'ant-design-vue'
import { useAbilityModelState } from './useState'
import AbilityModelEdit from './ability-model-edit.vue'
import { ThingModelEventTypeColorMap, ThingModelEventTypeMap } from '@/constant/thing-model'
import { deleteDeviceAbilityModelApi } from '@/api/device-model'

const { events, refresh } = useAbilityModelState()!
const choosedDeviceModelAbility = ref()
const open = ref(false)
const searchWords = ref()

const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '能力名称', dataIndex: 'abilityName' },
    { title: '能力编号', dataIndex: 'abilityCode' },
    { title: '参数', dataIndex: ['detail'] },
    { title: '事件类型', dataIndex: ['detail', 'type'], customRender({ record }) {
      return h(Tag, { color: ThingModelEventTypeColorMap.get(record.detail.type) }, () => ThingModelEventTypeMap.get(record.detail.type))
    } },
    { title: '备注', dataIndex: 'description' },
    { title: '操作', customRender({ record }) {
      return h(Space, { direction: 'horizontal', wrap: false }, () => [
        h(Button, { type: 'link', size: 'small', onClick: async () => {
          choosedDeviceModelAbility.value = record
          open.value = true
        } }, () => '修改'),
        h(
          Popconfirm,
          { title: '确定删除？', onConfirm: async () => {
            await deleteDeviceAbilityModelApi(record.id)
            await refresh()
          } },
          () => h(
            Button,
            { danger: true, type: 'link', size: 'small' },
            () => '删除',
          ),
        ),
      ])
    } },
  ],
  config: {
    total: 0,
    loading: false,
    isBorder: false,
    isSerialNo: true,
    isSelection: true,
    isOperate: true,
  },
  pagination: false,
})

watch(events, () => {
  state.data = events.value
}, { immediate: true })

function onSearch() {
  if (searchWords.value === '' || searchWords.value === undefined)
    state.data = events.value
  state.data = events.value.filter(item => item.abilityName.includes(searchWords.value))
}
</script>

<template>
  <base-table v-bind="state" :need-header="true">
    <template #header>
      <div flex justify-end>
        <a-space>
          <a-input v-model:value="searchWords" placeholder="请输入能力名称" allow-clear />
          <a-button type="primary" @click="onSearch">
            搜索
          </a-button>
        </a-space>
      </div>
    </template>
  </base-table>
  <AbilityModelEdit v-model:open="open" :device-model-ability="choosedDeviceModelAbility" @confirm="refresh" />
</template>
