<script setup lang="ts">
import { Button, Popconfirm, Space, Tag } from 'ant-design-vue'
import { useAbilityModelState } from './useState'
import AbilityModelEdit from './ability-model-edit.vue'
import { AccessModeTypeMap, SpecTypeMap } from '@/constant/thing-model'
import { deleteDeviceAbilityModelApi } from '@/api/device-model'

const { properties, refresh } = useAbilityModelState()!

const choosedDeviceModelAbility = ref()
const open = ref(false)
const searchWords = ref()
const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '能力名称', dataIndex: 'abilityName' },
    { title: '能力编号', dataIndex: 'abilityCode' },
    { title: '数据类型', dataIndex: ['detail', 'dataType', 'type'], customRender({ record }) {
      return SpecTypeMap.get(record.detail.dataType.type)
    } },
    { title: '数据定义', dataIndex: 'detail' },
    { title: '读写类型', dataIndex: ['detail', 'accessMode'], customRender({ record }) {
      return h(Tag, { color: AccessModeTypeMap.get(record.detail.accessMode)?.color ?? '' }, () => AccessModeTypeMap.get(record.detail.accessMode)?.text)
    } },
    { title: '备注', dataIndex: 'description' },
    { title: '操作', customRender({ record }) {
      return h(Space, { direction: 'horizontal', wrap: false }, () => [
        h(Button, { type: 'link', size: 'small', onClick: () => {
          choosedDeviceModelAbility.value = record
          open.value = true
        } }, () => '修改'),
        h(
          Popconfirm,
          { title: '确定删除？', onConfirm: async () => {
            await deleteDeviceAbilityModelApi(record.id)
            await refresh()
          } },
          () => h(
            Button,
            { danger: true, type: 'link', size: 'small' },
            () => '删除',
          ),
        ),
      ])
    } },
  ],
  config: {
    total: 0,
    loading: false,
    isBorder: false,
    isSerialNo: true,
    isSelection: true,
    isOperate: true,
  },
  pagination: false,
})

watch(properties, () => {
  state.data = properties.value
}, { immediate: true })

function onSearch() {
  if (searchWords.value === '' || searchWords.value === undefined)
    state.data = properties.value
  state.data = properties.value.filter(item => item.abilityName.includes(searchWords.value))
}
</script>

<template>
  <div h-full overflow-hidden flex flex-col>
    <base-table v-bind="state" :need-header="true">
      <template #header>
        <div flex justify-end>
          <a-space>
            <a-input v-model:value="searchWords" placeholder="请输入能力名称" allow-clear />
            <a-button type="primary" @click="onSearch">
              搜索
            </a-button>
          </a-space>
        </div>
      </template>
    </base-table>
  </div>
  <AbilityModelEdit v-model:open="open" :device-model-ability="choosedDeviceModelAbility" @confirm="refresh" />
</template>
