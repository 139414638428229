<script setup lang="ts">
interface Specs {
  length?: number
}

interface Props {
  specs: Specs
  preName: string[]
}

// 使用 withDefaults 创建 props
const props = withDefaults(defineProps<Props>(), {
  specs: () => ({}),
})

// 解构 specs prop 为响应式的本地属性
const { specs } = toRefs(props)
const checked = ref(false)

watchEffect(() => {
  checked.value = specs.value.length !== undefined
})

function onChange(val: any) {
  if (val === false) {
    Reflect.deleteProperty(specs.value, 'length')
  }
}
</script>

<template>
  <a-space flex items-center>
    <a-switch v-model:checked="checked" checked-children="限制长度" un-checked-children="不限制长度" @change="onChange" />
    <a-form-item v-if="checked" :name="[...preName, 'length']" required help="">
      <a-input-number v-model:value="specs.length" placeholder="请输入字符串长度">
        <template #addonAfter>
          字符
        </template>
      </a-input-number>
    </a-form-item>
  </a-space>
</template>

<style lang="scss" scoped>
.ant-form-item{
  margin-bottom: 0px;
}
</style>
