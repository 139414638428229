import { getDeviceAbilityModelListApi } from '@/api/device-model'

const [useProviderAbilityModelStore, useAbilityModelInject] = createInjectionState((props: any) => {
  const deviceAbilityModels = ref<any[]>([])
  const deviceModelCode = computed(() => props.deviceModelCode)

  const properties = computed(() => filter('PROPERTY'))
  const services = computed(() => filter('SERVICE'))
  const events = computed(() => filter('EVENT'))
  const profiles = computed(() => filter('PROFILE'))

  function filter(type: any) {
    return deviceAbilityModels.value.filter(abilityModel => abilityModel.abilityType === type)
  }

  onMounted(async () => {
    await refresh()
  })

  async function refresh() {
    deviceAbilityModels.value = await getDeviceAbilityModelListApi(deviceModelCode.value)
  }

  return {
    deviceAbilityModels,
    properties,
    services,
    events,
    profiles,
    deviceModelCode,
    refresh,
  }
})

export {
  useProviderAbilityModelStore,
}

export const useAbilityModelState = () => useAbilityModelInject()!
