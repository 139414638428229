<script setup lang="ts">
import type { ColumnsType } from 'ant-design-vue/es/table'
import _ from 'lodash-es'
import { SpecTypeMap } from '@/constant/thing-model'
import ThingModelArray from '@/components/custom/thing-model/thing-model-array.vue'
import ThingModelBool from '@/components/custom/thing-model/thing-model-bool.vue'
import ThingModelDate from '@/components/custom/thing-model/thing-model-date.vue'
import ThingModelDay from '@/components/custom/thing-model/thing-model-day.vue'
import ThingModelDecimal from '@/components/custom/thing-model/thing-model-decimal.vue'
import ThingModelEnum from '@/components/custom/thing-model/thing-model-enum.vue'
import ThingModelInt from '@/components/custom/thing-model/thing-model-int.vue'
import ThingModelStruct from '@/components/custom/thing-model/thing-model-struct.vue'
import ThingModelText from '@/components/custom/thing-model/thing-model-text.vue'

interface Props {
  specs: any[]
  preName: string[]
}

// 使用 withDefaults 创建 props
const props = withDefaults(defineProps<Props>(), {
  specs: () => [],
})

const open = ref(false)
const structItemSpecs = ref()
const formRef = ref()

const columns = ref<ColumnsType>([{ title: '参数名称', dataIndex: ['name'] }, { title: '参数类型', dataIndex: ['dataType', 'type'], customRender({ record }) {
  return SpecTypeMap.get(record.dataType.type)
} }, { title: '操作', dataIndex: 'operation' }])

// 解构 specs prop 为响应式的本地属性
const { specs } = toRefs(props)

function onDelete(index: number) {
  specs.value.splice(index, 1)
}

function onAdd() {
  open.value = true
  structItemSpecs.value = {
    name: '',
    identifier: '',
    required: false,
    dataType: {
      type: '',
      specs: {},
    },
  }
}

function onEdit(record: any) {
  open.value = true
  if (record.dataType.type === 'ENUM') {
    record.dataType.specs = _.map(record.dataType.specs, (value, key) => {
      return { keys: key, value }
    })
  }

  structItemSpecs.value = record
}

function onChangeType(type: string) {
  let specs
  switch (type) {
    case 'INT':
    case 'DECIMAL':
      specs = {
        min: 0,
        max: 1,
        step: 1,
        unit: '',
        unitName: '',
      }
      break
    case 'DATE':
    case 'TEXT':
      specs = {}
      break
    case 'ENUM':
      specs = []
      break
    case 'BOOL':
      specs = { true: '', false: '' }
      break

    default:
      break
  }
  structItemSpecs.value.dataType.specs = specs
}

function submit() {
  formRef.value.validate().then(() => {
    open.value = false
    formRef.value.resetFields()
  })
}

const thingModels: any = {
  INT: ThingModelInt,
  DECIMAL: ThingModelDecimal,
  DATE: ThingModelDate,
  DAY: ThingModelDay,
  ENUM: ThingModelEnum,
  BOOL: ThingModelBool,
  TEXT: ThingModelText,
  STRUCT: ThingModelStruct,
  ARRAY: ThingModelArray,
}
</script>

<template>
  <a-table :data-source="specs" :columns="columns" :pagination="false" :scroll="{ y: 200 }">
    <template #bodyCell="{ column, record, index }">
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a-button type="link" @click="onEdit(record)">
            编辑
          </a-button>
          <a-button type="link" danger @click="onDelete(index)">
            删除
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-button type="dashed" style="width: 100%;" @click="onAdd">
    添加
  </a-button>

  <a-modal v-model:open="open" width="50%" title="修改参数" :centered="true" @ok="submit">
    <a-form ref="formRef" :model="structItemSpecs" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="参数名称" :name="['name']" required>
        <a-input v-model:value="structItemSpecs.name" />
      </a-form-item>
      <a-form-item label="标识符" :name="['identifier']" required>
        <a-input v-model:value="structItemSpecs.identifier" />
      </a-form-item>
      <a-form-item label="是否必须" :name="['required']" required>
        <a-switch v-model:checked="structItemSpecs.required" />
      </a-form-item>
      <a-form-item label="数据类型" :name="['dataType', 'type']" required>
        <a-select
          v-model:value="structItemSpecs.dataType.type"
          :options="[
            { label: '整数型', value: 'INT' },
            { label: '浮点型', value: 'DECIMAL' },
            { label: '时间戳', value: 'DATE' },
            { label: '枚举型', value: 'ENUM' },
            { label: '布尔型', value: 'BOOL' },
            { label: '文本型', value: 'TEXT' },
          ]"
          @change="(val:any) => onChangeType(val)"
        />
      </a-form-item>
      <a-row v-if="structItemSpecs.dataType.type" style="margin-bottom: 24px;">
        <a-col :span="4" />
        <a-col :span="20">
          <a-card>
            <component :is="thingModels[structItemSpecs.dataType.type]" :specs="structItemSpecs.dataType.specs" :pre-name="['dataType', 'specs']" />
          </a-card>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<style lang="scss" scoped>
// .ant-form-item{
//   margin-bottom: 0px;
// }
</style>
