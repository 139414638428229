<script setup lang="ts">
import { PlusOutlined } from '@ant-design/icons-vue'
import type { FormInstance } from 'ant-design-vue'
import { cloneDeep } from 'lodash-es'
import { useAbilityModelState } from './useState'
import { addDeviceModelStatusApi, getDeviceModelExpressionApi, updateDeviceModelStatusApi } from '@/api/device-model'

const props = defineProps(['status', 'groupOptions'])
const emit = defineEmits(['confirm'])
const { deviceModelCode } = useAbilityModelState()
const message = useMessage()
const formRef = ref<FormInstance>()
const open = defineModel<boolean>('open')
const localStatus = ref({} as any)
const inputRef = ref()
const operation = ref('add')
const expressions = ref<any[]>([])
const localGroupOptions = ref<any[]>([])

const VNodes = defineComponent({
  props: {
    vnodes: {
      type: Object,
      required: true,
    },
  },
  render() {
    return this.vnodes
  },
})

function onSubmit() {
  formRef.value?.validate().then(async () => {
    if (operation.value === 'add') {
      localStatus.value.deviceModelCode = deviceModelCode.value
      await addDeviceModelStatusApi(localStatus.value)
      message.success('新增成功')
      open.value = false
      onCancel()
      emit('confirm')
    }
    else {
      await updateDeviceModelStatusApi(localStatus.value)
      message.success('修改成功')
      open.value = false
      onCancel()
      emit('confirm')
    }
  })
}

function onCancel() {
  formRef.value?.resetFields()
}

watchEffect(() => {
  if (props.status) {
    localStatus.value = cloneDeep(props.status)
    operation.value = 'update'
  }
  else {
    localStatus.value = {}
    operation.value = 'add'
  }

  localGroupOptions.value = cloneDeep(props.groupOptions).map((option: any) => ({ label: option, value: option }))

  getExpressions()
})

async function getExpressions() {
  expressions.value = await getDeviceModelExpressionApi(deviceModelCode.value)
}

const enterOptions = ref()
function addItem(e: any) {
  e.preventDefault()

  if (localGroupOptions.value.findIndex(item => item.label === enterOptions.value) === -1) {
    localGroupOptions.value.push({ label: enterOptions.value, value: enterOptions.value })
    enterOptions.value = ''
    setTimeout(() => {
      inputRef.value?.focus()
    }, 0)
  }
}
</script>

<template>
  <a-modal v-model:open="open" width="50%" :title="`${operation === 'update' ? '修改' : '新增'}`" :centered="true" @ok="onSubmit" @cancel="onCancel">
    <a-form ref="formRef" :model="localStatus" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="状态组名称" name="stateGroupName" required>
        <a-select v-model:value="localStatus.stateGroupName" :options="localGroupOptions">
          <template #dropdownRender="{ menuNode: menu }">
            <VNodes :vnodes="menu" />
            <a-divider style="margin: 4px 0" />
            <a-space style="padding: 4px 8px">
              <a-input ref="inputRef" v-model:value="enterOptions" placeholder="请输入状态组名称" />
              <a-button type="text" @click="addItem">
                <template #icon>
                  <PlusOutlined />
                </template>
                添加状态组
              </a-button>
            </a-space>
          </template>
        </a-select>
      </a-form-item>
      <a-form-item label="状态名称" name="stateName" required>
        <a-input v-model:value="localStatus.stateName" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="优先级" name="priority" required>
        <a-input-number v-model:value="localStatus.priority" />
      </a-form-item>
      <a-form-item label="规则表达式" name="deviceModelExpressionId" required>
        <a-select v-model:value="localStatus.deviceModelExpressionId" :options="expressions" :field-names="{ label: 'expressionName', value: 'id' }" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
