<script setup lang="ts">
interface Props {
  specs: any
  preName: string[]
}

// 使用 withDefaults 创建 props
const props = withDefaults(defineProps<Props>(), {
  specs: () => ({}),
})

// 解构 specs prop 为响应式的本地属性
const { specs } = toRefs(props)
</script>

<template>
  <a-form-item label="日期格式">
    <a-input placeholder="年月日格式的日期(例如：2023-09-09)" disabled style="width: 100%;" />
  </a-form-item>
</template>

<style lang="scss" scoped>
.ant-form-item{
  margin-bottom: 0px;
}
</style>
