<script setup lang="ts">
import { Button, Image, Popconfirm, Space } from 'ant-design-vue'
import RuleExpressionUpdate from './rule-expression-update.vue'
import { useAbilityModelState } from './useState'
import { deleteDeviceModelRulesExpressionApi, getDeviceModelExpressionApi } from '@/api/device-model'
import { copyExpressionToAllDevicesApi } from '@/api/event'
import { EventTypeMap, mapToSelectOptions } from '@/constant/event'

const { deviceModelCode } = useAbilityModelState()
const message = useMessage()
const copyRef = ref()
const expressionVisible = ref(false)
const updateModalVisible = ref(false)
const choosedExpression = ref()
const expressionData = ref({ eventType: undefined, deviceModelCode: deviceModelCode.value })

const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '表达式名称', dataIndex: 'expressionName' },
    { title: '表达式', dataIndex: 'expression' },
    { title: '绑定图标', dataIndex: 'bindIconPath', customRender: ({ record }) => { return h(Image, { src: record.bindIconPath, width: 40, preview: false }) } },
    { title: '图标优先级', dataIndex: 'bindIconPriority' },
    { title: '操作', dataIndex: '', customRender: ({ record }) => {
      return h(Space, {}, () => [
        h(Button, { type: 'link', size: 'small', onClick: () => {
          choosedExpression.value = record
          updateModalVisible.value = true
        } }, () => '编辑'),
        h(
          Popconfirm,
          { title: '确定删除？', onConfirm: async () => {
            await deleteDeviceModelRulesExpressionApi(record.id)
            await refresh()
          } },
          () => h(
            Button,
            { danger: true, type: 'link', size: 'small' },
            () => '删除',
          ),
        ),
      ])
    } },
  ],
  config: {
    total: 0, // 列表总数
    loading: false, // loading 加载
    isBorder: false, // 是否显示表格边框
    isSerialNo: true, // 是否显示表格序号
    isSelection: true, // 是否显示表格多选
    isOperate: true, // 是否显示表格操作栏
  },
  pagination: false,
})

onMounted(refresh)

function onAddExpression() {
  choosedExpression.value = undefined
  updateModalVisible.value = true
}

async function refresh() {
  state.config.loading = true
  state.data = await getDeviceModelExpressionApi(deviceModelCode.value)
  state.config.loading = false
  setTimeout(() => {
    state.config.loading = false
  }, 3000)
}

function reset() {
  expressionVisible.value = false
  copyRef.value.resetFields()
}

async function onCopy() {
  copyRef.value?.validate().then(async () => {
    const success = await copyExpressionToAllDevicesApi(expressionData.value)
    success && message.success('拷贝成功')
    reset()
    await refresh()
  })
}
</script>

<template>
  <flow-hidden-container>
    <table-layout>
      <template #headerLeft>
        <a-space>
          <a-button v-if="state.data.length > 0" type="primary" @click="() => { expressionVisible = true }">
            拷贝表达式
          </a-button>
          <a-button type="primary" @click="onAddExpression">
            新增表达式
          </a-button>
        </a-space>
      </template>
      <template #content>
        <base-table v-bind="state" />
      </template>
    </table-layout>
  </flow-hidden-container>
  <RuleExpressionUpdate v-model:open="updateModalVisible" :expression="choosedExpression" @confirm="refresh" />

  <a-modal v-model:open="expressionVisible" title="拷贝表达式" @ok="onCopy" @cancel="reset">
    <a-form ref="copyRef" :model="expressionData">
      <a-form-item label="事件类型" name="eventType" required>
        <a-select v-model:value="expressionData.eventType" :options="mapToSelectOptions(EventTypeMap)" />
      </a-form-item>
      该操作将使同模型的每一个设备与每一个表达式各绑定生成一个平台事件！
    </a-form>
  </a-modal>
</template>
