<script setup lang="ts">
interface Specs {
  min: number
  max: number
  step: number
  unit?: string
  unitName?: string
}

interface Props {
  specs: Specs
  preName: string[]
}

// 使用 withDefaults 创建 props
const props = withDefaults(defineProps<Props>(), {
  specs: () => ({
    min: 0,
    max: 1,
    step: 1,
    unit: '',
    unitName: '',
  }),
})

// 解构 specs prop 为响应式的本地属性
const { specs } = toRefs(props)
</script>

<template>
  <a-form-item :name="[...preName, 'min']" label="最小值" required>
    <a-input-number v-model:value="specs.min" style="width: 100%;" />
  </a-form-item>
  <a-form-item :name="[...preName, 'max']" label="最大值" required>
    <a-input-number v-model:value="specs.max" style="width: 100%;" />
  </a-form-item>
  <a-form-item :name="[...preName, 'step']" label="步长" required>
    <a-input-number v-model:value="specs.step" style="width: 100%;" />
    <a-alert message="最大支持小数点后六位" type="warning" closable style="margin-top: 10px;" />
  </a-form-item>
  <a-form-item :name="[...preName, 'unit']" label="单位符号">
    <a-input v-model:value="specs.unit" />
  </a-form-item>
  <a-form-item :name="[...preName, 'unitName']" label="单位名称">
    <a-input v-model:value="specs.unitName" />
  </a-form-item>
</template>
