<script setup lang="ts">
import { useAbilityModelState } from './useState'
import ProfileTable from './profile-table.vue'
import PropertyTable from './property-table.vue'
import EventTable from './event-table.vue'
import ServiceTable from './service-table.vue'
import AbilityModelEdit from './ability-model-edit.vue'
import { getAbilityModeThingModelApi } from '@/api/device-model'

const res = useAbilityModelState()!

const { profiles, properties, events, services, deviceModelCode, refresh } = res

const activeKey = ref('1')

const open = ref(false)
const thingModelVisible = ref(false)
const thingModel = ref()

function addAbilityModel() {
  open.value = true
}

async function onViewThingModel() {
  thingModel.value = await getAbilityModeThingModelApi({ deviceModelCode: deviceModelCode.value })
  thingModelVisible.value = true
}
</script>

<template>
  <a-card :borderer="false" h-full :body-style="{ height: '100%', overflow: 'hidden' }">
    <a-tabs v-model:active-key="activeKey" default-active-key="1" w-full h-full overflow-hidden>
      <a-tab-pane key="1">
        <template #tab>
          <a-badge :count="properties.length" color="blue" :show-zero="true">
            <div h-30px p-10px flex justify-center items-center>
              属性
            </div>
          </a-badge>
        </template>

        <flow-hidden-container>
          <PropertyTable />
        </flow-hidden-container>
      </a-tab-pane>
      <a-tab-pane key="2" force-render>
        <template #tab>
          <a-badge :count="services.length" color="blue" :show-zero="true">
            <div h-30px p-10px flex justify-center items-center>
              服务
            </div>
          </a-badge>
        </template>
        <flow-hidden-container>
          <ServiceTable />
        </flow-hidden-container>
      </a-tab-pane>
      <a-tab-pane key="3">
        <template #tab>
          <a-badge :count="events.length" color="blue" :show-zero="true">
            <div h-30px p-10px flex justify-center items-center>
              事件
            </div>
          </a-badge>
        </template>
        <flow-hidden-container>
          <EventTable />
        </flow-hidden-container>
      </a-tab-pane>
      <a-tab-pane key="4">
        <template #tab>
          <a-badge :count="profiles.length" color="blue" :show-zero="true">
            <div h-30px p-10px flex justify-center items-center>
              静态信息
            </div>
          </a-badge>
        </template>
        <flow-hidden-container>
          <ProfileTable />
        </flow-hidden-container>
      </a-tab-pane>

      <template #rightExtra>
        <a-space>
          <a-button type="primary" @click="onViewThingModel">
            查看物模型
          </a-button>
          <a-button type="primary" @click="addAbilityModel">
            添加设备能力
          </a-button>
        </a-space>
      </template>
    </a-tabs>
    <thing-model-export v-model:open="thingModelVisible" :thing-model="thingModel" />
    <AbilityModelEdit v-model:open="open" :device-model-ability="undefined" @confirm="refresh" />
  </a-card>
</template>

<style lang="scss" scoped>
:deep(.ant-tabs){
  .ant-tabs-content{
    height: 100%;
  }
}
</style>
